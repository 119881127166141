<template>
  <ez-content>
    <ez-container>
      <ez-box>
        <template slot="body">
          <ez-sub-header>
            <template slot="title">
              <div v-html="title" />
            </template>
          </ez-sub-header>
          <template v-if="!loading">
            <app-stub-box
              v-if="empty"
              :image="require('../assets/icons/snooze.svg')"
              :title="$t('funnels.no-data-title')"
              :description="$t('funnels.no-data-description')"
            />
            <ez-table
              class="table"
              v-if="!empty"
              :data="displayItems"
              stripe
              @sort-change="onSortChange"
            >
              <div slot="empty">{{ $t("no-data") }}</div>
              <ez-table-column
                prop="createDate"
                sortable
                :label="$t('funnels.fields.time')"
              >
                <template slot-scope="scope">{{
                  scope.row.createDate | userTime
                }}</template>
              </ez-table-column>
              <ez-table-column
                prop="funnelName"
                sortable
                :label="$t('funnels.fields.funnel-name')"
              >
                <template slot-scope="scope">{{
                  scope.row.funnelName
                }}</template>
              </ez-table-column>
              <ez-table-column
                prop="source"
                sortable
                :label="$t('funnels.fields.source')"
              >
                <template slot-scope="scope">{{ scope.row.source }}</template>
              </ez-table-column>
              <ez-table-column
                prop="completed"
                sortable
                :label="$t('funnels.fields.status')"
              >
                <template slot-scope="scope">{{
                  scope.row.completed
                    ? $t("funnels.fields.status-completed")
                    : $t("funnels.fields.status-not-completed")
                }}</template>
              </ez-table-column>
            </ez-table>
            <ez-pagination
              v-if="items.length"
              :page="pageNumber"
              :page-count="totalPages"
              :page-range="pageRange"
              :limit="pageSize"
              :limit-options="limitOptions"
              @change="onPaginationChange"
            />
          </template>
        </template>
      </ez-box>
    </ez-container>
  </ez-content>
</template>

<script>
import contactMixin from '@/mixins/contact';
import userTime from '@/services/formatters/userTime';
import * as getters from '../store/funnels/getters';
import * as actions from '../store/funnels/actions';

const STORE_PREFIX = 'funnels';

export default {
  mixins: [contactMixin],
  filters: {
    userTime,
  },
  data() {
    return {
      page: 1,
      pageCount: 15,
      pageRange: 7,
      limit: 10,
      limitOptions: [10, 25, 50],
      // defaultSort: { prop: 'time', order: 'descending' },
      title: this.$t('funnels.title'),
      pageTitle: this.$t('header.menuLinks.funnels'),
      // Temp recurring calls number (dubugging)
      callsNumber: 0,
    };
  },
  created() {
    // load contact's funnels
    this.loadItems({ sortProp: 'time', sortOrder: 'descending' });
  },
  methods: {
    // load data
    loadItems(params = {}) {
      // if (this.callsNumber > 15) { return; }
      // this.callsNumber++;
      const actualParams = {
        contactId: this.currentContactId,
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        ...params,
      };
      this.$store.dispatch(
        `${STORE_PREFIX}/${actions.GET_ITEMS}`,
        actualParams,
      );
    },

    // sorting
    onSortChange(value) {
      const prop = value.prop == null ? 'name' : value.prop;
      const order = value.order == null ? 'ascending' : value.order;
      this.loadItems({ pageNumber: 1, sortProp: prop, sortOrder: order });
    },

    // pagination
    onPaginationChange({ limit, page }) {
      this.loadItems({ pageSize: limit, pageNumber: page });
    },

    getDependencyTitle(item) {
      const { relationships } = item;

      if (relationships) {
        const { funnel } = relationships;
        if (funnel && funnel.data && funnel.data.id) {
          const obj = this.subItems[funnel.data.id];
          if (obj && obj.attributes) {
            return obj.attributes.name;
          }
        }
      }

      return '';
    },
  },
  computed: {
    // items to display in table and paging props
    data() {
      const data = this.$store.getters[`${STORE_PREFIX}/${getters.DATA}`];
      return data;
    },
    included() {
      return this.data && this.data.included ? this.data.included : [];
    },
    subItems() {
      const result = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const item of this.included) {
        result[item.id] = item;
      }
      return result;
    },
    items() {
      return this.data && this.data.data ? this.data.data : [];
    },
    displayItems() {
      return this.items.map((item) => {
        const { attributes } = item;

        const createDate = attributes['dt-last'];
        const { source } = attributes;
        const { completed } = attributes;
        const funnelName = this.getDependencyTitle(item);

        return {
          createDate,
          source,
          completed,
          funnelName,
        };
      });
    },
    totalItems() {
      return this.$store.getters[`${STORE_PREFIX}/${getters.TOTAL_ITEMS}`];
    },
    empty() {
      return this.totalItems === 0;
    },
    totalPages() {
      return this.$store.getters[`${STORE_PREFIX}/${getters.TOTAL_PAGES}`];
    },
    loading() {
      return this.$store.getters[`${STORE_PREFIX}/${getters.LOADING}`];
    },
    pageSize() {
      return this.$store.getters[`${STORE_PREFIX}/${getters.PAGE_SIZE}`];
    },
    pageNumber() {
      return this.$store.getters[`${STORE_PREFIX}/${getters.PAGE_NUMBER}`];
    },

    enabled() {
      return this.currentContact != null;
    },
  },
};
</script>
